import React,{useMemo} from 'react'
import { useParams } from 'react-router-dom';
import Header from '../components/Header'
import DataTable from 'react-data-table-component';
import { MdCreate,MdOutlineViewWeek } from "react-icons/md";
import { Link } from "react-router-dom";
import Client,{CachedClient} from '../client'
import {gql} from '@apollo/client';
import {StringToColour} from "../Utils";
import { Input } from 'reactstrap'
import {SearchEntity, queriesByID, searchFields} from '../components/SearchEntity'
import { GPGModal } from '../components/GPGModal';
import { usePopupManager } from "react-popup-manager";

const columns = [
    
    {
        name: 'ID',        
        
        selector: row => row.id,
        
        
        width: "350px",
        
        wrap: true,        
        sortable: true,
        sortField: "id",  
        omit:false,      
    },
    {
        name: 'Name',        
        
        selector: row => row.name,
        
        
        width: "200px",
        
        wrap: true,        
        sortable: true,
        sortField: "name",  
        omit:false,      
    },
    {
        name: 'Slug',        
        
        selector: row => row.slug,
        
        
        width: "200px",
        
        wrap: true,        
        sortable: true,
        sortField: "slug",  
        omit:false,      
    },
    {
        name: 'Bio',        
        
        selector: row => row.bio,
        
        
        width: "200px",
        
        wrap: true,        
        sortable: true,
        sortField: "bio",  
        omit:false,      
    },
    {
        name: 'Name Origin',        
        
        selector: row => row.name_origin,
        
        
        width: "200px",
        
        wrap: true,        
        sortable: true,
        sortField: "name_origin",  
        omit:false,      
    },
    {
        name: 'Main Image',        
        
        selector: row => row.main_image,
        
        
        width: "200px",
        
        wrap: true,        
        sortable: true,
        sortField: "main_image",  
        omit:false,      
    },
    {
        name: 'drag_type',        
        
        selector: row => row.drag_type,
        
        
        width: "200px",
        
        wrap: true,        
        sortable: true,
        sortField: "drag_type",  
        omit:false,      
    },
    {
        name: 'Main Drag Style',        
        
        selector: row => row.drag_style_primary,
        
        
        width: "200px",
        
        wrap: true,        
        sortable: true,
        sortField: "drag_style_primary",  
        omit:false,      
    },
    {
        name: 'Seconday Drag Style',        
        
        selector: row => row.drag_style_secondary,
        
        
        width: "200px",
        
        wrap: true,        
        sortable: true,
        sortField: "drag_style_secondary",  
        omit:false,      
    },
    {
        name: 'Third Drag Style',        
        
        selector: row => row.drag_style_tertiary,
        
        
        width: "200px",
        
        wrap: true,        
        sortable: true,
        sortField: "drag_style_tertiary",  
        omit:false,      
    },
    {
        name: 'Entrance Quote',        
        
        selector: row => "JSON Object",
        
        
        width: "200px",
        
        wrap: true,        
        sortable: true,
        sortField: "entrance_quote",  
        omit:false,      
    },
    {
        name: 'Farewell Message',        
        
        selector: row => "JSON Object",
        
        
        width: "200px",
        
        wrap: true,        
        sortable: true,
        sortField: "farewell_quote",  
        omit:false,      
    },
    {
        name: 'Names',        
        
        selector: row => "JSON Object",
        
        
        width: "200px",
        
        wrap: true,        
        sortable: true,
        sortField: "names",  
        omit:false,      
    },
    {
        name: 'Links',        
        
        selector: row => "JSON Object",
        
        
        width: "200px",
        
        wrap: true,        
        sortable: true,
        sortField: "links",  
        omit:false,      
    },
    {
        name: 'Media',        
        
        selector: row => "JSON Object",
        
        
        width: "200px",
        
        wrap: true,        
        sortable: true,
        sortField: "media",  
        omit:false,      
    },
    {
        name: 'Quotes',        
        
        selector: row => "JSON Object",
        
        
        width: "200px",
        
        wrap: true,        
        sortable: true,
        sortField: "quotes",  
        omit:false,      
    },
    {
        name: 'Email',        
        
        selector: row => row.email,
        
        
        width: "200px",
        
        wrap: true,        
        sortable: true,
        sortField: "email",  
        omit:false,      
    },
    {
        name: 'Locale',        
        
        selector: row => row.locale,
        
        
        width: "200px",
        
        wrap: true,        
        sortable: true,
        sortField: "locale",  
        omit:false,      
    },
    {
        name: 'Birthdate',        
        
        selector: row => row.birthdate,
        
        
        width: "200px",
        
        wrap: true,        
        sortable: true,
        sortField: "birthdate",  
        omit:false,      
    },
    {
        name: 'Ranking',        
        
        selector: row => row.ranking,
        
        
        width: "200px",
        
        wrap: true,        
        sortable: true,
        sortField: "ranking",  
        omit:false,      
    },
    {
        name: 'Gender',        
        
        selector: row => row.gender,
        
        
        width: "200px",
        
        wrap: true,        
        sortable: true,
        sortField: "gender",  
        omit:false,      
    },
    {
        name: 'Pronouns In Drag',        
        
        selector: row => row.pronouns_in,
        
        
        width: "200px",
        
        wrap: true,        
        sortable: true,
        sortField: "pronouns_in",  
        omit:false,      
    },
    {
        name: 'Pronouns out of Drag',        
        
        selector: row => row.pronouns_out,
        
        
        width: "200px",
        
        wrap: true,        
        sortable: true,
        sortField: "pronouns_out",  
        omit:false,      
    },
    {
        name: 'Ethnicity',        
        
        selector: row => row.ethnicity,
        
        
        width: "200px",
        
        wrap: true,        
        sortable: true,
        sortField: "ethnicity",  
        omit:false,      
    },
    {
        name: 'Status',        
        
        selector: row => row.status,
        
        
        width: "200px",
        
        wrap: true,        
        sortable: true,
        sortField: "status",  
        omit:false,      
    },
    {
        name: 'Created',        
        
        selector: row => row.created_at,
        
        
        width: "200px",
        
        wrap: true,        
        sortable: true,
        sortField: "created_at",  
        omit:false,      
    },
    {
        name: 'Updated',        
        
        selector: row => row.updated_at,
        
        
        width: "200px",
        
        wrap: true,        
        sortable: true,
        sortField: "updated_at",  
        omit:false,      
    },
    {
        name: 'User',        
        
        selector: row => {            
            return row.user_id_desc ? row.user_id_desc : row.user_id
        },
        
        
        width: "350px",
        
        wrap: true,        
        sortable: true,
        sortField: "user_id",  
        omit:false,      
    },
    {
        name: 'Location',        
        
        selector: row => {            
            return row.location_id_desc ? row.location_id_desc : row.location_id
        },
        
        
        width: "350px",
        
        wrap: true,        
        sortable: true,
        sortField: "location_id",  
        omit:false,      
    },
    {
        name: 'Home Town',        
        
        selector: row => {            
            return row.home_town_id_desc ? row.home_town_id_desc : row.home_town_id
        },
        
        
        width: "350px",
        
        wrap: true,        
        sortable: true,
        sortField: "home_town_id",  
        omit:false,      
    },
    {
        name: 'Drag Mother',        
        
        selector: row => {            
            return row.drag_mother_id_desc ? row.drag_mother_id_desc : row.drag_mother_id
        },
        
        
        width: "350px",
        
        wrap: true,        
        sortable: true,
        sortField: "drag_mother_id",  
        omit:false,      
    },
    {
        name: 'acting_score',        
        
        selector: row => row.acting_score,
        
        
        width: "200px",
        
        wrap: true,        
        sortable: true,
        sortField: "acting_score",  
        omit:false,      
    },
    {
        name: 'comedy_score',        
        
        selector: row => row.comedy_score,
        
        
        width: "200px",
        
        wrap: true,        
        sortable: true,
        sortField: "comedy_score",  
        omit:false,      
    },
    {
        name: 'dance_score',        
        
        selector: row => row.dance_score,
        
        
        width: "200px",
        
        wrap: true,        
        sortable: true,
        sortField: "dance_score",  
        omit:false,      
    },
    {
        name: 'design_score',        
        
        selector: row => row.design_score,
        
        
        width: "200px",
        
        wrap: true,        
        sortable: true,
        sortField: "design_score",  
        omit:false,      
    },
    {
        name: 'lipsync_score',        
        
        selector: row => row.lipsync_score,
        
        
        width: "200px",
        
        wrap: true,        
        sortable: true,
        sortField: "lipsync_score",  
        omit:false,      
    },    
];

var pressedKeys = {};

class Drag extends React.Component {
    constructor(props) {
        super(props)        
        this.state = { 
            pending:true,           
            items: [],
            pageSize:14,
            page:0,
            total: 10000,
            searchQuery:"",
            selectRefs: {},
            columns: [],            
        }

        
            
                this.state.selectRefs.user = React.createRef();
            
        
            
                this.state.selectRefs.location = React.createRef();
            
        
            
                this.state.selectRefs.drag = React.createRef();
            
        
    }

    componentDidMount() {        
        this.fetchItems();

        this.state.columns = [
            
            {
                identifier: "id",
                name: 'ID',        
                
                selector: row => row.id,
                
                
                width: "350px",
                
                wrap: true,        
                sortable: true,
                sortField: "id",  
                omit:false,      
            },
            {
                identifier: "name",
                name: 'Name',        
                
                selector: row => row.name,
                
                
                width: "200px",
                
                wrap: true,        
                sortable: true,
                sortField: "name",  
                omit:false,      
            },
            {
                identifier: "slug",
                name: 'Slug',        
                
                selector: row => row.slug,
                
                
                width: "200px",
                
                wrap: true,        
                sortable: true,
                sortField: "slug",  
                omit:false,      
            },
            {
                identifier: "bio",
                name: 'Bio',        
                
                selector: row => row.bio,
                
                
                width: "200px",
                
                wrap: true,        
                sortable: true,
                sortField: "bio",  
                omit:false,      
            },
            {
                identifier: "name_origin",
                name: 'Name Origin',        
                
                selector: row => row.name_origin,
                
                
                width: "200px",
                
                wrap: true,        
                sortable: true,
                sortField: "name_origin",  
                omit:false,      
            },
            {
                identifier: "main_image",
                name: 'Main Image',        
                
                selector: row => row.main_image,
                
                
                width: "200px",
                
                wrap: true,        
                sortable: true,
                sortField: "main_image",  
                omit:false,      
            },
            {
                identifier: "drag_type",
                name: 'drag_type',        
                
                selector: row => row.drag_type,
                
                
                width: "200px",
                
                wrap: true,        
                sortable: true,
                sortField: "drag_type",  
                omit:false,      
            },
            {
                identifier: "drag_style_primary",
                name: 'Main Drag Style',        
                
                selector: row => row.drag_style_primary,
                
                
                width: "200px",
                
                wrap: true,        
                sortable: true,
                sortField: "drag_style_primary",  
                omit:false,      
            },
            {
                identifier: "drag_style_secondary",
                name: 'Seconday Drag Style',        
                
                selector: row => row.drag_style_secondary,
                
                
                width: "200px",
                
                wrap: true,        
                sortable: true,
                sortField: "drag_style_secondary",  
                omit:false,      
            },
            {
                identifier: "drag_style_tertiary",
                name: 'Third Drag Style',        
                
                selector: row => row.drag_style_tertiary,
                
                
                width: "200px",
                
                wrap: true,        
                sortable: true,
                sortField: "drag_style_tertiary",  
                omit:false,      
            },
            {
                identifier: "entrance_quote",
                name: 'Entrance Quote',        
                
                selector: row => "JSON Object",
                
                
                width: "200px",
                
                wrap: true,        
                sortable: true,
                sortField: "entrance_quote",  
                omit:false,      
            },
            {
                identifier: "farewell_quote",
                name: 'Farewell Message',        
                
                selector: row => "JSON Object",
                
                
                width: "200px",
                
                wrap: true,        
                sortable: true,
                sortField: "farewell_quote",  
                omit:false,      
            },
            {
                identifier: "names",
                name: 'Names',        
                
                selector: row => "JSON Object",
                
                
                width: "200px",
                
                wrap: true,        
                sortable: true,
                sortField: "names",  
                omit:false,      
            },
            {
                identifier: "links",
                name: 'Links',        
                
                selector: row => "JSON Object",
                
                
                width: "200px",
                
                wrap: true,        
                sortable: true,
                sortField: "links",  
                omit:false,      
            },
            {
                identifier: "media",
                name: 'Media',        
                
                selector: row => "JSON Object",
                
                
                width: "200px",
                
                wrap: true,        
                sortable: true,
                sortField: "media",  
                omit:false,      
            },
            {
                identifier: "quotes",
                name: 'Quotes',        
                
                selector: row => "JSON Object",
                
                
                width: "200px",
                
                wrap: true,        
                sortable: true,
                sortField: "quotes",  
                omit:false,      
            },
            {
                identifier: "email",
                name: 'Email',        
                
                selector: row => row.email,
                
                
                width: "200px",
                
                wrap: true,        
                sortable: true,
                sortField: "email",  
                omit:false,      
            },
            {
                identifier: "locale",
                name: 'Locale',        
                
                selector: row => row.locale,
                
                
                width: "200px",
                
                wrap: true,        
                sortable: true,
                sortField: "locale",  
                omit:false,      
            },
            {
                identifier: "birthdate",
                name: 'Birthdate',        
                
                selector: row => row.birthdate,
                
                
                width: "200px",
                
                wrap: true,        
                sortable: true,
                sortField: "birthdate",  
                omit:false,      
            },
            {
                identifier: "ranking",
                name: 'Ranking',        
                
                selector: row => row.ranking,
                
                
                width: "200px",
                
                wrap: true,        
                sortable: true,
                sortField: "ranking",  
                omit:false,      
            },
            {
                identifier: "gender",
                name: 'Gender',        
                
                selector: row => row.gender,
                
                
                width: "200px",
                
                wrap: true,        
                sortable: true,
                sortField: "gender",  
                omit:false,      
            },
            {
                identifier: "pronouns_in",
                name: 'Pronouns In Drag',        
                
                selector: row => row.pronouns_in,
                
                
                width: "200px",
                
                wrap: true,        
                sortable: true,
                sortField: "pronouns_in",  
                omit:false,      
            },
            {
                identifier: "pronouns_out",
                name: 'Pronouns out of Drag',        
                
                selector: row => row.pronouns_out,
                
                
                width: "200px",
                
                wrap: true,        
                sortable: true,
                sortField: "pronouns_out",  
                omit:false,      
            },
            {
                identifier: "ethnicity",
                name: 'Ethnicity',        
                
                selector: row => row.ethnicity,
                
                
                width: "200px",
                
                wrap: true,        
                sortable: true,
                sortField: "ethnicity",  
                omit:false,      
            },
            {
                identifier: "status",
                name: 'Status',        
                
                selector: row => row.status,
                
                
                width: "200px",
                
                wrap: true,        
                sortable: true,
                sortField: "status",  
                omit:false,      
            },
            {
                identifier: "created_at",
                name: 'Created',        
                
                selector: row => row.created_at,
                
                
                width: "200px",
                
                wrap: true,        
                sortable: true,
                sortField: "created_at",  
                omit:false,      
            },
            {
                identifier: "updated_at",
                name: 'Updated',        
                
                selector: row => row.updated_at,
                
                
                width: "200px",
                
                wrap: true,        
                sortable: true,
                sortField: "updated_at",  
                omit:false,      
            },
            {
                identifier: "user_id",
                name: 'User',        
                
                selector: row => {            
                    return row.user_id_desc ? row.user_id_desc : row.user_id
                },
                
                
                width: "350px",
                
                wrap: true,        
                sortable: true,
                sortField: "user_id",  
                omit:false,      
            },
            {
                identifier: "location_id",
                name: 'Location',        
                
                selector: row => {            
                    return row.location_id_desc ? row.location_id_desc : row.location_id
                },
                
                
                width: "350px",
                
                wrap: true,        
                sortable: true,
                sortField: "location_id",  
                omit:false,      
            },
            {
                identifier: "home_town_id",
                name: 'Home Town',        
                
                selector: row => {            
                    return row.home_town_id_desc ? row.home_town_id_desc : row.home_town_id
                },
                
                
                width: "350px",
                
                wrap: true,        
                sortable: true,
                sortField: "home_town_id",  
                omit:false,      
            },
            {
                identifier: "drag_mother_id",
                name: 'Drag Mother',        
                
                selector: row => {            
                    return row.drag_mother_id_desc ? row.drag_mother_id_desc : row.drag_mother_id
                },
                
                
                width: "350px",
                
                wrap: true,        
                sortable: true,
                sortField: "drag_mother_id",  
                omit:false,      
            },
            {
                identifier: "acting_score",
                name: 'acting_score',        
                
                selector: row => row.acting_score,
                
                
                width: "200px",
                
                wrap: true,        
                sortable: true,
                sortField: "acting_score",  
                omit:false,      
            },
            {
                identifier: "comedy_score",
                name: 'comedy_score',        
                
                selector: row => row.comedy_score,
                
                
                width: "200px",
                
                wrap: true,        
                sortable: true,
                sortField: "comedy_score",  
                omit:false,      
            },
            {
                identifier: "dance_score",
                name: 'dance_score',        
                
                selector: row => row.dance_score,
                
                
                width: "200px",
                
                wrap: true,        
                sortable: true,
                sortField: "dance_score",  
                omit:false,      
            },
            {
                identifier: "design_score",
                name: 'design_score',        
                
                selector: row => row.design_score,
                
                
                width: "200px",
                
                wrap: true,        
                sortable: true,
                sortField: "design_score",  
                omit:false,      
            },
            {
                identifier: "lipsync_score",
                name: 'lipsync_score',        
                
                selector: row => row.lipsync_score,
                
                
                width: "200px",
                
                wrap: true,        
                sortable: true,
                sortField: "lipsync_score",  
                omit:false,      
            },    
        ]   

        var cachedColumns = localStorage.getItem("drag_columns")     
        var parsedCachedColumns = JSON.parse(cachedColumns)        
        if (parsedCachedColumns !== undefined && parsedCachedColumns !== null) {            
            this.state.columns.map((column, index) => {                
                this.state.columns[index].omit = parsedCachedColumns[column.identifier]
            })
        }        
        this.setState(this.state)

        window.onkeyup = function(e) { pressedKeys[e.keyCode] = false; }
        window.onkeydown = function(e) { pressedKeys[e.keyCode] = true; }

    }

    fetchItems() {        
        const offset = this.state.page*this.state.pageSize        
        Client
            .query({
                query: gql`
                query DragByStatus($limit: Int, $offset: Int) {
                    DragByStatus(status:"enabled",limit:$limit, offset:$offset){ 
                        id,
                        name,
                        slug,
                        bio,
                        name_origin,
                        main_image,
                        drag_type,
                        drag_style_primary,
                        drag_style_secondary,
                        drag_style_tertiary,
                        entrance_quote{
                            season_id,quote,
                        },
                        farewell_quote{
                            season_id,quote,
                        },
                        names{
                            type,value,
                        },
                        links{
                            type,link,
                        },
                        media{
                            type,url,description,
                        },
                        quotes{
                            text,date,details,
                        },
                        email,
                        locale,
                        birthdate,
                        ranking,
                        gender,
                        pronouns_in,
                        pronouns_out,
                        ethnicity,
                        status,
                        created_at,
                        updated_at,
                        user_id,
                        location_id,
                        home_town_id,
                        drag_mother_id,
                        acting_score,
                        comedy_score,
                        dance_score,
                        design_score,
                        lipsync_score,
                    }
                }
                `,
                variables:{
                    limit:this.state.pageSize,
                    offset:offset
                }
            })
            .then((result) => {
                
                const items = result.data.DragByStatus
                if (items.length < this.state.pageSize){
                    this.state.total = ((this.state.page)*this.state.pageSize)+items.length
                }                
                                       
                Promise.allSettled(EntityRefPromises(items)).then(()=>{
                    setTimeout(() => {                    
                        this.state.pending = false
                        this.state.items = items                        
                        this.setState(this.state)
                    }, 500)
                })
            });
            
    }

    searchItems() {        
        const offset = this.state.page*this.state.pageSize        
        Client
            .query({
                query: gql`
                query SearchDrag($query:String!, $limit: Int, $offset: Int) {
                    SearchDrag(query:$query,limit:$limit, offset:$offset){ 
                        id,
                        name,
                        slug,
                        bio,
                        name_origin,
                        main_image,
                        drag_type,
                        drag_style_primary,
                        drag_style_secondary,
                        drag_style_tertiary,
                        entrance_quote{
                            season_id,quote,
                        },
                        farewell_quote{
                            season_id,quote,
                        },
                        names{
                            type,value,
                        },
                        links{
                            type,link,
                        },
                        media{
                            type,url,description,
                        },
                        quotes{
                            text,date,details,
                        },
                        email,
                        locale,
                        birthdate,
                        ranking,
                        gender,
                        pronouns_in,
                        pronouns_out,
                        ethnicity,
                        status,
                        created_at,
                        updated_at,
                        user_id,
                        location_id,
                        home_town_id,
                        drag_mother_id,
                        acting_score,
                        comedy_score,
                        dance_score,
                        design_score,
                        lipsync_score,
                    }
                }
                `,
                variables:{
                    query:this.state.searchQuery,
                    limit:this.state.pageSize,
                    offset:offset
                }
            })
            .then((result) => {
                
                const items = result.data.SearchDrag
                if (items.length < this.state.pageSize){
                    this.state.total = ((this.state.page)*this.state.pageSize)+items.length
                }                
                Promise.allSettled(EntityRefPromises(items)).then(()=>{
                    setTimeout(() => {                    
                        this.state.pending = false
                        this.state.items = items                        
                        this.setState(this.state)
                    }, 500)
                })
            });
    }

    load(){
        if (this.state.searchQuery !== "") {
            this.searchItems()
        } else {
            this.fetchItems()
        }
    }

    

    render() {
        return (
            <div>
                <Header />  
                <div className="actionsBar">                     
                    <div className="searchBar">                    
                        <Input
                            type="text"
                            name="search"
                            onChange={(e) => {
                                this.state.searchQuery = e.target.value
                                this.setState(this.state)
                                this.load()
                            } }
                            placeholder="Search"                                   
                            value={this.state.searchQuery}                                                          
                        />   
                    </div>                                
                    <a onClick={() => {
                                    this.props.popups.open(GPGModal, {
                                                title: <b>Edit Columns</b>,
                                                content: <Columns 
                                                    columns={this.state.columns} 
                                                    onChange={ (columns) => {
                                                        this.state.columns = [ ...columns]
                                                        const omit = {}
                                                        columns.map((column) => {
                                                            omit[column.identifier] = column.omit
                                                        })
                                                        localStorage.setItem("drag_columns", JSON.stringify(omit))
                                                        this.setState(this.state)
                                                    } }
                                                 />,
                                                onClose: (...params) => {
                                                }
                                                }); 
                                }}><MdOutlineViewWeek /> Columns</a>
                    <Link to="/drag/edit"><MdCreate /> Create</Link>                                                         
                </div>
                <div className="filters">
                        
                            
                            <div className="filter">
                                <SearchEntity 
                                    entityIdentifier="user" 
                                    entityName="User"
                                    onChange={(e, entityIdentifier) => {   
                                        for (const selectRef in this.state.selectRefs) { 
                                            if (selectRef !== entityIdentifier){ 
                                                this.state.selectRefs[selectRef].current.clearValue();
                                            }
                                        }                                        
                                        if (e !== null) {                                   
                                            this.state.searchQuery = e.value
                                            this.setState(this.state)
                                            this.load() 
                                        } else {
                                            this.state.searchQuery = ""
                                            this.setState(this.state)
                                            this.load()                                                                                         
                                        }                                            
                                    } }
                                    placeholder={"Search User"}
                                    value={this.state.searchQuery}
                                    ref={this.state.selectRefs.user}
                                    />
                            </div>
                            
                        
                            
                            <div className="filter">
                                <SearchEntity 
                                    entityIdentifier="location" 
                                    entityName="Location"
                                    onChange={(e, entityIdentifier) => {   
                                        for (const selectRef in this.state.selectRefs) { 
                                            if (selectRef !== entityIdentifier){ 
                                                this.state.selectRefs[selectRef].current.clearValue();
                                            }
                                        }                                        
                                        if (e !== null) {                                   
                                            this.state.searchQuery = e.value
                                            this.setState(this.state)
                                            this.load() 
                                        } else {
                                            this.state.searchQuery = ""
                                            this.setState(this.state)
                                            this.load()                                                                                         
                                        }                                            
                                    } }
                                    placeholder={"Search Location"}
                                    value={this.state.searchQuery}
                                    ref={this.state.selectRefs.location}
                                    />
                            </div>
                            
                        
                            
                            <div className="filter">
                                <SearchEntity 
                                    entityIdentifier="drag" 
                                    entityName="Drag"
                                    onChange={(e, entityIdentifier) => {   
                                        for (const selectRef in this.state.selectRefs) { 
                                            if (selectRef !== entityIdentifier){ 
                                                this.state.selectRefs[selectRef].current.clearValue();
                                            }
                                        }                                        
                                        if (e !== null) {                                   
                                            this.state.searchQuery = e.value
                                            this.setState(this.state)
                                            this.load() 
                                        } else {
                                            this.state.searchQuery = ""
                                            this.setState(this.state)
                                            this.load()                                                                                         
                                        }                                            
                                    } }
                                    placeholder={"Search Drag"}
                                    value={this.state.searchQuery}
                                    ref={this.state.selectRefs.drag}
                                    />
                            </div>
                            
                        
                        <div className="clear"></div>
                    </div>
                <div className="tableWrapper" style={{borderTopColor:StringToColour('drag')}}>                                                             
                <DataTable
                    title="Drag"
                    highlightOnHover="true"
                    pointerOnHover="true"
                    pagination="true"
                    paginationServer="true"
                    paginationPerPage={this.state.pageSize}
                    columns={this.state.columns}
                    data={this.state.items}
                    pending={this.state.pending} 
                    paginationTotalRows={this.state.total}                   
			        onChangePage={(page) => {                            
                            this.state.page = page-1 
                            this.setState(this.state)	
                            this.load()
                        }
                    }
                    onChangeRowsPerPage={(size, page) => {
                            this.state.page = page-1
                            this.state.pageSize = size
                            this.setState(this.state)  
                            this.load()                        
                        }
                    }
                    onRowClicked={(row) => {                             
                            if (pressedKeys[91]) {
                                var url = "/drag/edit/"+row.id;
                                window.open(url, '_blank', 'noopener,noreferrer').focus();
                            } else {
                                window.location.href = "/drag/edit/"+row.id;                           
                            }
                        }
                    }
                />
                </div>
            </div>
        )
    }
}



export default (props) => <Drag {...props}
            params={useParams()} popups={usePopupManager()} />

class Columns extends React.Component {
    constructor(props) {
        super(props)        
        this.state = {             
            columns: props.columns
        }
    }

    render() {
        return (
            <ul className="columnsEdit">
                {this.state.columns.map((column, index) => 
                    <li key={index} >                                                        
                        <Input
                            type="checkbox"
                            name={column.name}
                            onChange={(e) => {                                
                                this.state.columns[index].omit = !e.target.checked
                                this.setState(this.state)
                                this.props.onChange(this.state.columns)
                            } }                                                                                                    
                            value={ !this.state.columns[index].omit }
                            checked={ !this.state.columns[index].omit }                                                                                                
                        />
                        <span>{column.name}</span>
                    </li>
                )}
            </ul>
        )
    }
}


function EntityRefPromises(items) {
     return items.map((item, index) => { 
        var res = [] 
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
            if (item.user_id !== "" && item.user_id !== null){
                res.push(CachedClient
                    .query({
                        query: queriesByID.get("user"),
                        variables:{
                            id:item.user_id,                                       
                        }
                    })
                    .then((result) => {  
                        var data = result.data["UserByID"]
                        if (data.length > 0) {
                            var fieldsFunc = searchFields.get("user")
                            const desc = fieldsFunc(data[0])
                            items[index] = { ...items[index],
                                    user_id_obj:data[0],
                                    user_id_desc: desc,
                                    }                                        
                        }
                    })
                )
            }
        
        
        
            if (item.location_id !== "" && item.location_id !== null){
                res.push(CachedClient
                    .query({
                        query: queriesByID.get("location"),
                        variables:{
                            id:item.location_id,                                       
                        }
                    })
                    .then((result) => {  
                        var data = result.data["LocationByID"]
                        if (data.length > 0) {
                            var fieldsFunc = searchFields.get("location")
                            const desc = fieldsFunc(data[0])
                            items[index] = { ...items[index],
                                    location_id_obj:data[0],
                                    location_id_desc: desc,
                                    }                                        
                        }
                    })
                )
            }
        
        
        
            if (item.home_town_id !== "" && item.home_town_id !== null){
                res.push(CachedClient
                    .query({
                        query: queriesByID.get("location"),
                        variables:{
                            id:item.home_town_id,                                       
                        }
                    })
                    .then((result) => {  
                        var data = result.data["LocationByID"]
                        if (data.length > 0) {
                            var fieldsFunc = searchFields.get("location")
                            const desc = fieldsFunc(data[0])
                            items[index] = { ...items[index],
                                    home_town_id_obj:data[0],
                                    home_town_id_desc: desc,
                                    }                                        
                        }
                    })
                )
            }
        
        
        
            if (item.drag_mother_id !== "" && item.drag_mother_id !== null){
                res.push(CachedClient
                    .query({
                        query: queriesByID.get("drag"),
                        variables:{
                            id:item.drag_mother_id,                                       
                        }
                    })
                    .then((result) => {  
                        var data = result.data["DragByID"]
                        if (data.length > 0) {
                            var fieldsFunc = searchFields.get("drag")
                            const desc = fieldsFunc(data[0])
                            items[index] = { ...items[index],
                                    drag_mother_id_obj:data[0],
                                    drag_mother_id_desc: desc,
                                    }                                        
                        }
                    })
                )
            }
        
        
        
        
        
        
        
        
        
        
        
        
        return res
    })
}